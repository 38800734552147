import refreshToken from '@/plugins/refresh-token'
import { api } from './main'
import { api as api2 } from './mainNode'

const branchList = async () => {
  await refreshToken()
  const response = await api
    .get({
      path: 'ref/shopbranch',
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.log('get branch list err : ', err)

      return {}
    })

  return response
}
const branchListForSelection = async () => {
  await refreshToken()
  const response = await api2
    .get({
      path: 'shopBranch',
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.log('get branch list err : ', err)

      return {}
    })

  return response
}
const branchListGet = async body => {
  await refreshToken()
  const response = await api
    .post({
      path: 'agent/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get branch list err : ', err)

      return {}
    })

  return response
}
const branchGet = async id => {
  await refreshToken()
  const response = await api
    .get({
      path: 'agent',
      param: id,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get branch err : ', err)

      return {}
    })

  return response
}
const branchAdd = async body => {
  await refreshToken()
  const response = await api
    .post({
      path: 'agent',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add branch err : ', err)

      return {}
    })

  return response
}
const branchUpdate = async body => {
  await refreshToken()
  const response = await api
    .put({
      path: 'agent',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update branch err : ', err)

      return {}
    })

  return response
}
const branchStatusUpdate = async body => {
  await refreshToken()
  const response = await api
    .put({
      path: 'agent/status',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update branch status err : ', err)

      return {}
    })

  return response
}

export default {
  branchList,
  branchListGet,
  branchAdd,
  branchUpdate,
  branchListForSelection,
  branchGet,
  branchStatusUpdate,
}
