import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const documentSetting = async () => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'documentsetting',
    })
    .then(res => (res.data ? res.data : {}))
    .catch(err => {
      console.log('get document err : ', err)

      return {}
    })

  return response
}

const smsList = async () => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'documentsetting/headsms',
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.log('get sms list err : ', err)

      return []
    })

  return response
}

const documentUpdate = async (path, body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path,
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update document err : ', err)

      return {}
    })

  return response
}

const commissionSetting = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'documentsetting/commission',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update commmission err : ', err)

      return {}
    })

  return response
}

const vatSetting = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'documentsetting/vat',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update vat err : ', err)

      return {}
    })

  return response
}

const printSetting = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'documentsetting/print',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update print err : ', err)

      return {}
    })

  return response
}
const cashbackSetting = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'documentsetting/cashback',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update print err : ', err)

      return {}
    })


  return response
}

const addSmsTopic = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'documentsetting/headsms',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add sms topic err : ', err)

      return {}
    })

  return response
}

const updateSmsTopic = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'documentsetting/headsms',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update sms topic err : ', err)

      return {}
    })

  return response
}

const deleteSmsTopic = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'documentsetting/headsms',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('delete sms topic err : ', err)

      return {}
    })

  return response
}

export default {
  documentSetting,
  smsList,
  documentUpdate,
  printSetting,
  commissionSetting,
  vatSetting,
  addSmsTopic,
  cashbackSetting,
  updateSmsTopic,
  deleteSmsTopic,
}
