<template>
  <v-chip
    :color="type == 1?'primary':'info'"
    :class="type==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
    small
  >
    <v-icon
      small
      class="me-1"
    >
      {{ type == 1 ?icons.mdiPill:icons.mdiNeedle }}
    </v-icon>
    {{ type == 1 ? $t('drug') : $t('equipment') }}
  </v-chip>
</template>

<script>
import { mdiPill, mdiNeedle } from '@mdi/js'

export default {
  props: { type: String },
  setup() {
    return {
      icons: {
        mdiPill,
        mdiNeedle,
      },
    }
  },
}
</script>
