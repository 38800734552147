import branch from '@/api/branch'
import transferCreate from '@/api/drugTransfer/transferCreate'
import document from '@/api/systemSetting/document'
import userRole from '@/api/userRole'
import footerDataTables from '@/components/basicComponents/footerDataTables-sm.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useCreateTransfer() {
  const searchtext = ref('')

  const columns = ref([
    { text: '#', value: 'drug_id_pri', width: 30 },
    {
      text: i18n.t('type'),
      value: 'drug_category_id',
      align: 'center',
      width: 100,
    },
    { text: i18n.t('code'), value: 'drug_id', width: 80 },
    { text: i18n.t('name'), value: 'drug_name', width: 200 },
    {
      text: i18n.t('price'),
      value: 'drug_price',
      width: 150,
      align: 'end',
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      width: 100,
      align: 'center fixed',
    },
  ])
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const options = ref({})
  const loading = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const segmentId = ref(0)

  const shopList = ref([])
  const shop_id_pri = ref('')
  const userList = ref([])
  const user_id = ref({})

  const drugorder_id_pri = ref('')
  const transferorder_amount = ref(0)
  const transferorder_price = ref(0)

  const shop_id_pri_to = ref('')
  const transfer_cost_id = ref(1)
  const transfer_comment = ref('')

  const cartList = ref([])

  const transfer_code = ref('')

  const isSelect = ref(false)
  const detailData = ref([])
  const selectedDrug = ref([])
  const detailData_list = ref([])
  const statusCheckCount = ref(false)
  const addCardLoading = ref(false)

  const loadingRemove = ref(false)

  const loadingAdd = ref(false)

  const isUserComfirm = ref(false)

  const transfer_cost_data = ref([
    { value: 2, label: i18n.t('not_export_cost') },
    { value: 1, label: i18n.t('export_cost') },
  ])

  const { documentSetting } = document
  const {
    transferCreateDrugList,
    transferCreateAddCart,
    transferCreateCartList,
    transferCreateRemoveCart,
    transferCreateAdd,
  } = transferCreate
  const { branchList } = branch
  const { userRoleList } = userRole
  onMounted(() => {
    getDocumentSetting()
    getDrugList()
    getDataCartList()
  })
  branchList().then(res => {
    shopList.value = res
    shop_id_pri.value = res[0].shop_id_pri
  })

  userRoleList({
    role_id: ['1', '3'],
  }).then(res => {
    userList.value = res
    user_id.value = localStorage.getItem('user_id')
  })

  const getDocumentSetting = async () => {
    documentSetting().then(res => {
      transfer_code.value = res.transfer
    })
  }

  const getDrugList = () => {
    loading.value = true
    transferCreateDrugList({
      searchtext: searchtext.value,
      druggroup_id: '',
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment } = res
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      loading.value = false
    })
  }

  watch([searchtext, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0]) {
      options.value.page = 1
    }
    getDrugList()
  })

  const showDetail = item => {
    let i
    for (i = 0; i < item.drugorder.length; i++) {
      item.drugorder[i].transferorder_amount = 0
    }
    if (i == item.drugorder.length) {
      detailData.value = item
      detailData_list.value = item.drugorder
    }
    selectedDrug.value = []
    isSelect.value = true
  }

  const sum = (item, index) => {
    detailData_list.value[index] = item
    detailData_list.value = [...detailData_list.value]
    for (let i = 0; i < detailData_list.value.length; i++) {
      for (let j = 0; j < selectedDrug.value.length; j++) {
        if (detailData_list.value[i].drugorder_id_pri == selectedDrug.value[j].drugorder_id_pri) {
          if (
            selectedDrug.value[j].transferorder_amount != 0 &&
            selectedDrug.value[j].transferorder_amount != '' &&
            +selectedDrug.value[j].transferorder_amount >= 1 &&
            selectedDrug.value[j].transferorder_amount != null
          ) {
            statusCheckCount.value = true
          } else {
            statusCheckCount.value = false
            break
          }
        }
      }
    }
  }

  const getDataCartList = () => {
    transferCreateCartList().then(res => {
      cartList.value = res
    })
  }
  const addDataDrug = () => {
    addCardLoading.value = true
    let dataStatus = true
    selectedDrug.value.forEach(element => {
      if (parseFloat(element.transferorder_amount) > parseFloat(element.drugordert_total)) {
        dataStatus = false
      }
    })
    if (dataStatus == false) {
      store.commit('app/ALERT', {
        message: 'amountInputThenMoreBalance',
        color: 'error',
      })
      addCardLoading.value = false
    } else {
      const drugorder_id_pri = []
      const transferorder_amount = []
      const transferorder_price = []
      selectedDrug.value.forEach(element => {
        drugorder_id_pri.push(element.drugorder_id_pri)
        transferorder_amount.push(element.transferorder_amount)
        transferorder_price.push(element.drugorder_cost)
      })
      const body = {
        drugorder_id_pri,
        transferorder_amount,
        transferorder_price,
      }
      transferCreateAddCart(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getDrugList()
        getDataCartList()
        isSelect.value = false
        addCardLoading.value = false
      })
    }
  }

  const deleteDataDrug = id => {
    loadingRemove.value = true
    transferCreateRemoveCart(id).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      loadingRemove.value = false
      getDrugList()
      getDataCartList()
    })
  }

  const createTransfer = () => {
    loadingAdd.value = true
    transferCreateAdd({
      shop_id_pri_to: shop_id_pri.value,
      transfer_cost_id: transfer_cost_id.value,
      transfer_comment: `${transfer_comment.value} (${i18n.t('more_comment')}: ${user_id.value.user_fullname})`,
      lang: i18n.locale,
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      getDrugList()
      getDataCartList()
      getDocumentSetting()
      transfer_comment.value = ''
      searchtext.value = ''
      user_id.value = localStorage.getItem('user_id')
      shop_id_pri.value = shopList.value[0].shop_id_pri
      loadingAdd.value = false
    })
  }

  watch(
    () => selectedDrug.value,
    () => {
      for (let i = 0; i < selectedDrug.value.length; i++) {
        if (
          selectedDrug.value[i].transferorder_amount != 0 &&
          selectedDrug.value[i].transferorder_amount != '' &&
          +selectedDrug.value[i].transferorder_amount >= 1 &&
          selectedDrug.value[i].transferorder_amount != null
        ) {
          statusCheckCount.value = true
        } else {
          statusCheckCount.value = false
          break
        }
      }
    },
  )

  return {
    searchtext,
    columns,
    dataTableList,
    totalDataTableList,
    options,
    loading,
    footer,
    segmentId,
    shopList,
    shop_id_pri,
    userList,
    user_id,
    drugorder_id_pri,
    transferorder_amount,
    transferorder_price,
    shop_id_pri_to,
    transfer_cost_id,
    transfer_comment,
    cartList,
    transfer_code,
    transfer_cost_data,

    isSelect,
    detailData,
    selectedDrug,
    detailData_list,
    statusCheckCount,
    addCardLoading,
    loadingRemove,

    isUserComfirm,
    loadingAdd,

    createTransfer,
    deleteDataDrug,
    getDocumentSetting,
    getDrugList,
    showDetail,
    sum,
    addDataDrug,
  }
}
