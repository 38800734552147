import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const transferCreateDrugList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'transfer/drug',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get drug list err : ', err)

      return {}
    })

  return response
}

const transferCreateCartList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'transfer/cartlist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get cart list err : ', err)

      return []
    })

  return response
}
const transferCreateAddCart = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'transfer/cart',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add drug to cart err : ', err)

      return {}
    })

  return response
}
const transferCreateRemoveCart = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'transfer/cart',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('delete  drug to cart err : ', err)

      return {}
    })

  return response
}

const transferCreateAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'transfer',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add transfer err : ', err)

      return {}
    })

  return response
}
export default {
  transferCreateDrugList, transferCreateAddCart, transferCreateCartList, transferCreateRemoveCart, transferCreateAdd,
}
